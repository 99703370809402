<template>
  <dash-page-new
      :subtitle="$t('Storage')"
      :root="$t('Dashboard')"
      :title="$t('Storage') + ': ' + storageSize + ' / ' + storageSizeQuota + ' GB' "
      icon="mdi-server"
      :filters="filters"

    >


    <template #header_action>

      <div  class="d-flex ">
        <v-btn
            v-if="bufferItemsIds.length > 0 || displayUpload"
            @click="bufferItems = []; displayUpload = false"
            class="mr-2 noCaps"  outlined   :color="wsACCENT">
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
            v-if="bufferItemsIds.length > 0"
            @click="displayMoveToFolder = true"
            class="mr-2 noCaps"  outlined   :color="wsACCENT">
          {{ $t('file_browser.move_to_folder') }}
        </v-btn>
      </div>

      <ft-select
          v-if="selectedFolder !== 'shared'"
          :items="headerActionsSelect"
      >
        <v-btn class="mr-2 noCaps" elevation="0"  dark   :color="wsATTENTION">{{ $t('Add') }}</v-btn>
      </ft-select>

    </template>

    <template #default>

      <div class="fill-height" style="position : relative">
        <!-- Breadcrumbs-->
        <v-sheet width="100%" class="d-flex align-center" style="position : absolute; z-index : 2" color="transparent" height="60">
          <div class="d-flex align-center">
            <div class="d-flex align-center" v-for="(item , i) in location" :key="i">
              <h5 @click="gotoBreadcrumbFolder(item)" class="pointer linkHover" :style="`color : ${wsACCENT}`">
                {{ item.name === 'Shared' ? $t('file_browser.shared_folder') : item.name      }}
              </h5>
              <v-icon>mdi-chevron-right</v-icon>
            </div>
            <h5>{{  selectedFolderName === 'Shared' ? $t('file_browser.shared_folder') : selectedFolderName   }}</h5>
          </div>
        </v-sheet>
        <!-- Browser-->
        <v-sheet
            :style="displayUpload ? 'padding-bottom : 200px' : ''"
            style="transition: all 0.3s ease; position:relative"
            class="flex-grow-1 fill-height pt-16" color="transparent"
        >
          <div  class="d-flex flex-row fill-height">

            <v-sheet color="transparent" min-width="300"  class="fill-height overflow-y-auto pr-5">
              <storage-side-navigation
                  :selected-folder="selectedFolder"
                  :update-trigger="sidebarUpdateTrigger"
                  @select="gotoNavigationFolder"
              />
            </v-sheet>

            <!-- BROWSER FILES TABLE -->
            <v-sheet color="transparent" width="100%" class="flex-grow-1 fill-height  d-flex flex-column">

              <!-- Table Header -->

              <div class="v-data-table wsHoverLight v-data-table--dense v-data-table--has-bottom theme--light ">
                <div class="v-data-table__wrapper">
                  <portal-target name="storage_table_header" tag="table"  />
                </div>
              </div>

              <!-- Table Content -->
              <v-sheet
                  :style="`border-left : 1px solid ${wsBACKGROUND} !important;border-right : 1px solid ${wsBACKGROUND} !important;border-bottom : 1px solid ${wsBACKGROUND} !important`"
                  style="border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;"
                  color="transparent" class="flex-grow-1 overflow-y-auto">
                <ws-data-table
                    :items="itemsFiltered"
                    :headers="headers"
                    :search="$store.state.settings.filtersData.search"
                    dense
                    :row-action="openAction"
                    :context-actions-select="itemActionsSelect"
                    @select-item="selectedItem = $event"
                    :selected-rows="bufferItemsIds"
                    footer-portal="storage-table-footer"
                    class="mb-n5"
                >

                  <template #header="{props , on}">
                    <portal to="storage_table_header">
                      <ws-table-header
                          :headers="headers"
                          :props="props" :on="on"
                      />
                    </portal>

                  </template>


                  <template #item.select="{item , hover }">
                    <v-sheet @click.stop="selectItem(item)" class="fill-height d-flex align-center" color="transparent" height="30" width="26">
                      <v-icon class="ml-1" v-if="!item.is_return && (hover || bufferItemsIds.length > 0)" :color="wsACCENT">{{ !bufferItemsIds.includes(item.uuid) ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked-outline' }}</v-icon>
                    </v-sheet>

                  </template>

                  <template #item.name="{item}">
                    <h5 class="shorten-text" :style="bufferItemsIds.includes(item.uuid) ? `color : ${wsACCENT}` : null">
                      <v-icon :color="bufferItemsIds.includes(item.uuid) ? wsACCENT : wsATTENTION">{{ getIcon(item.mime) }}</v-icon>
                      {{ item.name }}
                    </h5>
                  </template>

                  <template #item.relations_count="{item}">
                    <div>
                      <h5 v-if="!item.is_folder &&item.relations_count > 0" class="text-center" > {{ item.relations_count   }}</h5>
                    </div>
                  </template>

                  <template #item.is_public="{item}">
                    <div class="d-flex justify-center">
                      <v-icon small v-if="item.is_public" :color="wsACCENT" >mdi-eye</v-icon>
                    </div>
                  </template>

                  <template #item.shared_users_count="{item}">
                    <h5 class="text-no-wrap">
                      {{
                        item.shared_users_count > 0
                            ? `${$t('Users')}: ${item.shared_users_count}`
                            :  $t('file_browser.not_shared')
                      }}
                    </h5>
                  </template>


                  <template #item.size="{item}">
                    <div >
                      <h5 class="text-no-wrap" v-if="!item.is_folder" >{{ PARSE_FILE_SIZE(item.size)    }}</h5>
                    </div>
                  </template>

                  <template #item.date_added="{item , hover}">
                    <h5 v-if="!hover && !item.is_return"  class="font-weight-medium text-no-wrap"> {{ MONTH_DAY_TIME(item.date_added ) }}</h5>
                    <div v-else-if="!item.is_return" class="d-flex align-center justify-end">

                      <v-btn v-if="item.is_public" @click.stop="copyUrl(item)" :color="wsACCENT" height="24" min-width="24" text width="24">
                        <v-icon>mdi-link</v-icon>
                      </v-btn>

                      <v-btn @click="downloadFile(item)" @click.stop :color="wsACCENT" height="24" min-width="24" text width="24">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>

                      <ft-select
                          :items="itemActionsSelect"
                      >
                        <v-btn :color="wsACCENT"  height="24" min-width="24" width="24" text>
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </ft-select>

                    </div>

                  </template>

                </ws-data-table>
              </v-sheet>

              <!-- Table Footer -->
              <v-sheet min-height="60px" :style="`border : 1px solid ${wsBACKGROUND}`" class="px-5 d-flex justify-end align-center wsRoundedLight mt-4">
                <portal-target name="storage-table-footer"></portal-target>
              </v-sheet>


            </v-sheet>
          </div>
        </v-sheet>
        <!-- Upload -->
        <v-expand-transition>
          <v-sheet
              v-if="displayUpload"
              color="transparent"
              class="py-5"
              style="position : absolute; z-index : 2; bottom : 0; transition : all 0.3s ease"
              width="100%"
              height="200"
          >
           <ws-file-uploader
               @success="addFile"
               :folder="selectedFolder"
           />
          </v-sheet>
        </v-expand-transition>

      </div>


    </template>

    <template #dialog>
      <!-- File Details Dialog-->
      <wsDialog
          :title="$t('FileDetails')"
          v-model="displayDeleteDialog"
          @save="editFile()"
          @delete="deleteFile"
          show-delete
          width="700"
      >
        <ws-text-field
            v-model="selectedFile.name"
            :label="$t('Name')"
            :placeholder="$t('FileName')"
        >
          <template #append>
            <h5 class="mt-1" v-if="selectedFile.ext !== selectedFile.name">{{ selectedFile.ext }}</h5>
          </template>
        </ws-text-field>

        <h5 class="font-weight-medium mt-5" :style="`color : ${wsACCENT}`"> {{ $t('FileInfo') }}: </h5>
        <h5 class="mt-3" > {{ $t('FileSize') }} : {{ PARSE_FILE_SIZE(selectedFile.size)  }}</h5>
        <h5 > {{ $t('FileType') }} : {{ getFileType(selectedFile.mime) }}</h5>
        <h5 class="mb-5" > {{ $t('FileRelations') }} : {{ selectedFile.relations_count }}</h5>


      </wsDialog>
      <!-- Folder Dialog-->
      <ws-dialog
          v-model="displayFolderDialog"
          @save="addEditFolder"
          :save-text="newFolder ? $t('Create') : $t('Save')"
          :title="newFolder ? $t('AddFolder') : $t('EditFolder')"
      >
        <ws-text-field
            v-model="folderEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('file_browser.folder.enter_name')"
        />
      </ws-dialog>
      <!-- Move to folder Dialog-->
      <ws-dialog
          v-if="displayMoveToFolder"
          v-model="displayMoveToFolder"
          :title="$t('file_browser.move_to_folder')"
          hide-buttons
          width="400"
          max-height="400px"
          height="90%"
          not-centered
      >

        <storage-side-navigation
            @select="pasteItems"
            :buffer-items="bufferItemsIds"
            full-width
        />

      </ws-dialog>
      <!-- Access Dialog-->
      <file-access-dialog
          v-if="displayFileAccessDialog"
          v-model="displayFileAccessDialog"
          :item="selectedItem"
          @update-shared-users="updateSharedCount"
      />
    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import wsTableHeader from "@/components/UI/tables/wsTableHeader";
import storageSideNavigation from "@/components/pages/businessDashboard/businessStorage/storageSideNavigation";
import fileAccessDialog from "@/components/pages/businessDashboard/businessStorage/fileAccessDialog";
export default {
  name: "settingsStorage",
  components : {
    wsTableHeader,
    storageSideNavigation,
    fileAccessDialog
  },
  data() {
    return {
      displayDeleteDialog : false,
      selectedFile : {},
      items : [],
      folders : [],
      storageSize : 0,
      storageSizeQuota : 0,
      displayUpload : false,

      // folders
      displayFolderDialog : false,
      newFolder : false,
      folderEntityData : {},
      selectedFolder : null,
      selectedFolderName : '',
      returnFolder : null,
      selectedItem : {},
      bufferItems : [],
      location : [],
      locationNames : [],
      displaySelect : false,
      sidebarUpdateTrigger : 0,
      displayMoveToFolder : false,
      // file access
      displayFileAccessDialog: false,

    }
  },
  computed : {

    isBrowsingShared() {
      return this.location.filter(el => el.uuid === 'shared').length > 0 || this.selectedFolder === 'shared'
    },
    headers() {
      let items = [
        { value: 'select', width: 10 , padding : 0 },
        { text : this.$t('Name') , value : 'name' , padding : 4 },
        { text : this.$t('file_browser.relations') , value : 'relations_count' , width : 10 },
        { text : this.$t('file_browser.is_public') , value : 'is_public' , width : 10  },
        { text : this.$t('file_browser.shared_to') , value : 'shared_users_count' , width : 10  },
        { text : this.$t('FileSize') , value : 'size' , width : 10  },
        { text : this.$t('DateCreated') , value : 'date_added' , width : 10  },
      ]

      return items
    },
    bufferItemsIds() {
      return this.bufferItems.map( el => el.uuid)
    },
    itemActionsSelect() {
      let items = []
      if ( this.selectedItem.is_return) {
        return []
      }

      if ( this.bufferItemsIds.length > 0   ) {
        items =  [ { text : this.$t('Select')  , icon : 'mdi-checkbox-marked-outline' ,  action : () =>  this.selectItem(this.selectedItem)} ]
      } else {
        items = [
          { text : this.$t('Select')       , icon : 'mdi-checkbox-marked-outline' , action : () =>  this.selectItem(this.selectedItem)     },
          { text : this.$t('Settings')     , icon : 'mdi-cog' , action : () =>  this.openAction(this.selectedItem)     },
          { text : this.$t('file_browser.share') , icon : 'mdi-share-variant-outline' , action : () => this.openFileAccessDialog(this.selectedItem) },
          { text : this.$t('Download')     , icon : 'mdi-download' , action : () =>  this.downloadFile(this.selectedItem)     },
          { text : this.$t('file_browser.move_to_folder') , icon : 'mdi-folder-move-outline' , action : () =>  this.openMoveToFolder([this.selectedItem]) },
        ]

        if ( this.selectedItem.mime !== 'folder' && this.selectedItem.is_public) {
          items.push({
            text : this.$t('Link'),
            icon : 'mdi-link',
            action : () => this.copyUrl(this.selectedItem)
          })
        }

        items.push({ text : this.$t('Delete') , icon : 'mdi-delete' , action :  this.openDeleteFolder })

      }

      if ( this.selectedItem.mime === 'folder' &&  this.bufferItems.length > 0 ) {
        items.unshift({
          text : this.$t('Paste'),
          icon : 'mdi-folder-move',
          action : this.pasteItems
        })
      }

      return items
    },
    headerActionsSelect() {
      return [
        { text : this.$t('UploadFile') , action : this.openUpload },
        // { text : this.$t('AddFolder') , action  : this.openAddFolder }
      ]
    },

    itemsFiltered() {
      let items = this.COPY(this.items)

      if ( this.selectedFolder ) {
        items.unshift({
          name : '...',
          is_folder : true,
          is_return : true,
          mime : 'folder'
        })
      }


      return items

    },
    filters() {
      return [
          { text : this.$t('FileRelations')  , value : 'relations' , type : 'select' , items : [
              { text : this.$t('CourseContent') , value : 'course_content' },
              { text : this.$t('CourseDescription') , value : 'course_description' },
              { text : this.$t('CourseImage') , value : 'course_image' },
              { text : this.$t('StudentReply') , value : 'student_reply' },
              { text : this.$t('QuestionBank') , value : 'question_bank' },
              { text : this.$t('TeacherReply') , value : 'teacher_reply' },
            ] },
      ]
    }
  },
  watch : {
    selectedFolder() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('storage', ['GET_STORAGE_INFO' , 'DELETE_FILE' , 'EDIT_FILE_NAME']),
    ...mapActions('file_browser', [
        'ADD_EDIT_FOLDER' ,
        'GET_FILE_BROWSER',
        'MOVE_TO_FOLDER'
    ]),

    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    addFile(event) {
      this.items.push(event)
    },
    async downloadFile(item) {

      let result = await this.GET_PRIVATE_FILE(item.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },


    openDeleteFolder() {
      console.log('Delete Folder')
    },

    updateSharedCount(value) {

      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid)
      if ( index === -1 ) {
        return
      }
      this.items[index].shared_users_count += value
      this.items = this.COPY(this.items)
    },
    // File Acccess
    openFileAccessDialog(item) {
      this.selectedFile = this.COPY(item)
      this.displayFileAccessDialog = true
    },

    // Context Actions Methods
    cutItems(items) {
      this.bufferItems = this.COPY(items)
    },
    openMoveToFolder(items) {
      this.displayMoveToFolder = true
      this.bufferItems = this.COPY(items)
    },
    async pasteItems(item = null) {
      if ( !item ) {
        item = this.selectedItem
      }
      let data = {
        folder_id : item.uuid,
        items : this.bufferItems.map( el=> ({ is_folder : el.mime === 'folder' , id : el.uuid }))
      }
      let result = await this.MOVE_TO_FOLDER(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.items = this.items.filter( el => !this.bufferItemsIds.includes(el.uuid))
      this.sidebarUpdateTrigger++
      this.bufferItems = []
      this.displayMoveToFolder = false
    },
    async copyUrl(item) {
      await navigator.clipboard.writeText(item.url);
      this.notify(this.$t('CopiedToClipboard') )
    },

    // Header Actions
    openUpload() {
      this.displayUpload = true
    },
    openAddFolder() {
      this.folderEntityData = {}
      this.displayFolderDialog = true
      this.newFolder = true
    },
    async addEditFolder() {
      if ( this.selectedFolder ) {
        this.folderEntityData.parent = this.selectedFolder
      }
      let result = await this.ADD_EDIT_FOLDER(this.folderEntityData)
      if ( !result ) {
        return
      }
      if ( this.newFolder ) {
        let lastFolderIndex = this.items.filter(el => el.is_folder).length
        if (lastFolderIndex < 0 ) { lastFolderIndex = 0 }
        this.items.splice(lastFolderIndex , 0 , result)
        this.folders.push(result)

        this.sidebarUpdateTrigger++
      }
      this.displayFolderDialog = false
      // this.notify(this.$t('FolderCreated') , 'red')
    },

    // Items Methods
    selectItem(item) {

      if (this.bufferItemsIds.includes(item.uuid) ) {
        this.bufferItems = this.bufferItems.filter(el => el.uuid !== item.uuid)
      }
      else if ( item.is_return ) {
        return
      } else {
        this.bufferItems.push(this.COPY(item))
        this.bufferItems = this.COPY(this.bufferItems)
      }
    },
    openAction(item) {
      if ( item.is_return) {
        this.selectedFolder = this.location[this.location.length - 1].uuid
        this.selectedFolderName = this.location[this.location.length - 1].name
        this.location.splice(-1,1)
      }
      else if ( this.bufferItemsIds.length > 0 ) {
        this.selectItem(item)
      }
      else if ( !item.is_folder) {
        this.openDialog(item)
      } else {
        this.items = []
        this.location.push({
          uuid : this.selectedFolder,
          name : this.selectedFolderName
        })
        this.selectedFolder = item.uuid
        this.selectedFolderName = item.name

      }
    },
    openDialog(file) {
      let expl = file.name.split('.')
      let ext = expl[expl.length -1]
      expl.splice(-1,1);
      let name = expl.join('.')
      this.selectedFile = JSON.parse(JSON.stringify(file))
      this.selectedFile.ext = ext
      this.selectedFile.name = name
      this.displayDeleteDialog = true
    },
    async editFile() {

      let result = await this.EDIT_FILE_NAME(this.selectedFile)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el=>el.uuid === this.selectedFile.uuid )
      if ( index === -1 ) {
        return
      }

      this.items[index].name= this.selectedFile.name + '.' + this.selectedFile.ext
      this.displayDeleteDialog = false


    },
    async deleteFile() {

      let result = await this.DELETE_FILE(this.selectedFile.uuid)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el=>el.uuid === this.selectedFile.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index,1)
      this.displayDeleteDialog = false

      this.storageSize = (this.storageSize - (this.selectedFile.size/1000/1000/1000) ).toFixed(2)
      this.notify(this.$t('FileDeleted'))

    },

    // technical
    gotoNavigationFolder($event){
      $event.location.unshift({
        uuid : null , name :  this.$t('file_browser.root')
      })
      this.location = $event.location
      this.selectedFolder = $event.uuid
      this.selectedFolderName = $event.name
    },
     gotoBreadcrumbFolder(item) {
      let index = this.location.findIndex(el => el.uuid === item.uuid)
      this.location.splice(index)
      this.selectedFolderName = item.name
      this.selectedFolder = item.uuid
    },

    getIcon(mime) {

      if (!mime ) {
        return 'mdi-file-outline'
      }

      if ( mime.includes('image') ) {
        return 'mdi-file-image-outline'
      }

      if ( mime.includes('video') ) {
        return 'mdi-file-video-outline'
      }

      if ( mime === 'folder'  ) {
        return 'mdi-folder'
      }

      return 'mdi-file-outline'

    },
    getFileType(mime) {
      if (!mime ) {
        return this.$t('File')
      }

      if ( mime.includes('image') ) {
        return this.$t('Image')
      }

      if ( mime.includes('video') ) {
        return this.$t('Video')
      }

      return this.$t('File')

    },
    getFolderName(uuid) {
      let item = this.items.find(el => el.uuid === uuid)
      if ( item ) {
        return item.name
      }
      return item ? item.name : this.$t('Root')
    },

    async initPage() {
      let data = {
        'location' : this.selectedFolder || 'root',
        'shared' : this.isBrowsingShared
      }
      let result = await this.GET_FILE_BROWSER(data)
      if ( !result || result === true) {
        return
      }

      this.items = result.items
      this.storageSize  = result.storage_size
      this.storageSizeQuota  = result.storage_size_quota
    },
  },
  mounted() {
    this.selectedFolderName = this.$t('file_browser.root')
    this.$store.state.settings.filters = this.filters

    this.initPage()

  },
  beforeDestroy() {
    this.$store.state.settings.filters = []

  }
}
</script>

<style scoped>

</style>