<template>
  <v-sheet
      :style="`border : 1px solid ${wsBACKGROUND}`"
      class="fill-height wsRoundedLight px-3 pa-4 overflow-y-auto"
      :class="[{'mr-10' : !fullWidth}]"
      :width="!fullWidth ? '280' : null"
      height="100%"
   >

    <navigation-folder
        v-for="(item , i) in rootItems" :key="i"
        :item="item"
        :folders="itemsFiltered"
        :selected-folder="selectedFolder"
        @select="$emit('select' , {uuid : $event.uuid , name: $event.name , location : $event.location} )"
    />

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
import navigationFolder from "@/components/pages/businessDashboard/businessStorage/navigationFolder";
export default {
  name: "storageSideNavigation",
  components : {
    navigationFolder
  },
  props : {
    selectedFolder : {},
    updateTrigger : {
      type : Number,
      default : 0
    },
    fullWidth : {
      type : Boolean,
      default : false
    },
    bufferItems : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      items : []
    }
  },
  computed : {
    rootItems() {
      return this.items.filter(el => !el.parent && !this.bufferItems.includes(el.uuid))
    },
    itemsFiltered() {
      return this.items.filter(el => !this.bufferItems.includes(el.uuid))
    }
  },
  watch : {
    updateTrigger() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('file_browser', [
      'ADD_EDIT_FOLDER' ,
      'GET_FILE_BROWSER',
      'GET_FILE_BROWSER_FOLDERS',
      'MOVE_TO_FOLDER'

    ]),

    async initPage() {
      let result = await this.GET_FILE_BROWSER_FOLDERS()
      if ( !result || result === true) {
        return
      }
      this.items = result.folders
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>