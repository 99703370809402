<template>
  <div>
    <h5 class="py-1 d-flex align-center "
        :class="[{'font-weight-regular' : selectedFolder !== item.uuid}]"
    >
      <v-hover #default="{hover}">
        <v-sheet @click="expand = !expand" :color="hover ? wsLIGHTCARD : 'transparent'" class="pointer wsRoundedLight" v-ripple min-width="24">
          <v-icon v-if="children.length > 0" :color="wsACCENT">mdi-menu-{{ expand ?  'down' : 'right' }}</v-icon>
        </v-sheet>
      </v-hover>

      <v-icon class="mr-2" :color="isShared ? wsACCENT : wsATTENTION" size="18">
        <template v-if="isShared && !child">
          mdi-folder-account
        </template>
        <template v-else>
           mdi-folder{{expand ? '-open' : ''}}
        </template>
      </v-icon>

      <span @click="select"  :style="`color : ${wsACCENT}`" class="pointer linkHover shorten-text">
        {{ !item.is_shared ?  item.name : $t('file_browser.shared_folder') }}
      </span>
    </h5>


    <v-expand-transition>
      <div v-if="children.length > 0 && expand" >
        <div  class="pl-5">
          <navigation-folder
              v-for="(item , i) in children" :key="i"
              :item="item"
              :folders="folders"
              :selected-folder="selectedFolder"
              @select="handleSelect"
              :is-shared="isShared"
              child
          />
        </div>
      </div>

    </v-expand-transition>

  </div>
</template>

<script>
export default {
  name: "navigationFolder",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    folders : {
      type : Array,
      default() { return [] }
    },
    selectedFolder : {},
    child : {
      type : Boolean,
      default : false
    },
    isShared : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      expand : false,
      location : [],
    }
  },
  computed : {
    children() {
      return this.folders.filter(el => el.parent === this.item.uuid)
    }
  },
  watch : {
    selectedFolder(value) {
      if ( value === this.item.uuid && this.children.length > 0) {
        this.expand = true
      }
    }
  },
  methods : {
    select() {
      this.location = []
      this.$emit('select' , {uuid : this.item.uuid , name : this.item.name , parent : this.item.parent , parent_name : this.item.parent_name , location : this.location})
    },
    handleSelect($event) {
      this.location = $event.location
      this.location.unshift({uuid : $event.parent , name : $event.parent_name})

      this.$emit('select' , {uuid : $event.uuid , name : $event.name, parent : this.item.parent , parent_name : this.item.parent_name , location : this.location})
      this.location = []
    }
  },
  mounted() {
    if ( this.item.is_shared ) {
      this.isShared = true
    }
  }
}
</script>

<style scoped>

</style>